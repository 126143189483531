<template>
  <div>
    <base-dialog
      :visible.sync="visibleState"
      :showFooter="false"
      title="选择合同信息"
    >
      <base-form
        :componentList="contractConfig"
        :showBtns="true"
        :formAttrs="{
          model: contractQueryParas,
          labelWidth: '120px',
        }"
        @handleFilter="handleFilter"
        @clearParams="resetFrom"
        class="formStyle"
        ref="addfromdata"
      />
      <!-- 数据表格 -->
      <base-table
        class="main-page-table"
        :columns="contractTableConfig"
        :showPage="false"
        :queryParas.sync="contractQueryParas"
        :tableAttrs="{
          data: tableData,
          stripe: true,
        }"
        :loadCount="loadCount"
        :api="api"
        :getApi="'getPage'"
        ref="tableData"
        :webPage="false"
        :dataSource.sync="tableData"
        @selection-change="handleSelectionChange"
      >
      </base-table>
      <template slot="footer">
        <base-button label="提 交" @click="submit" />
        <base-button
          label="关 闭"
          type="default"
          @click="visibleState = false"
        />
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { contractDdApi } from '@/api/businessApi'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import {
  contractConfig,
  contractTableConfig
} from '../utils/project-contract-info'
import BaseForm from '@/components/common/base-form//base-form'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
export default {
  components: { BaseDialog, BaseButton, BaseForm, BaseTable },
  props: {
    show: Boolean,
    contractIds: String,
    gysId: String
  },
  data () {
    return {
      loadCount: 0,
      contractQueryParas: {
        pageIndex: 1,
        pageSize: 99999999
      },
      tableData: [

      ]
    }
  },
  // 计算属性 类似于data概念
  computed: {
    api () {
      return contractDdApi
    },
    contractTableConfig () {
      return contractTableConfig(this)
    },
    contractConfig () {
      return contractConfig(this)
    },
    visibleState: {
      get () {
        return this.show
      },
      set (val) {
        this.$emit('update:show', val)
      }
    }
  },
  // 监控data中的数据变化
  watch: {
    visibleState (val) {
      if (val) {
        this.handleFilter()
      }
    }
  },
  // 方法集合
  methods: {

    handleSelectionChange (val) {
      this.changeData = val
    },
    submit () {
      this.$emit('choseContract', this.changeData)
      this.visibleState = false
    },
    handleFilter () {
      this.contractQueryParas.contractIds = this.contractIds
      this.contractQueryParas.gysId = this.gysId
      this.api.getDdBaseContracts(this.contractQueryParas).then(res => {
        console.log(res.data, 111)
        this.tableData = res.data
      })
    },
    /**
     * 重置表单
     */
    resetFrom () {
      this.contractQueryParas = {}
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
</style>
