// 项目合同信息
<template>
  <div style="height: 100%">
    <!-- 合同文件 -->
    <base-table
      class="main-page-table with-add"
      id="ordertableList"
      :columns="tableConfig"
      :showPage="false"
      :tableAttrs="{
        data: contractData,
        stripe: true
      }"
      ref="tableData"
      :webPage="false"
      :isCaculateHeight="false"
    >
      <template slot="radio" slot-scope="scope">
        <div class="radio">
          <el-radio
            v-model="scope.row.chose"
            @change="change(scope.$index)"
            :label="true"
            >&nbsp;</el-radio
          >
        </div>
      </template>
    </base-table>
    <!-- 滚动内容 -->
    <div class="scrollBox" :id="scrollBoxId">
      <!-- 合同文件表单 -->
      <div>
        <text-title
          index="01"
          label="基本信息"
          data-title="基本信息"
          class="modelBox"
        ></text-title>
        <!-- 基本信息 -->

        <form-info
          :componentList="formConfig"
          :model="contractData[index]"
          :showbor="false"
          :count="count"
        />
        <!-- 付款情况 -->
        <TitleCenter name="付款情况" />
        <form-info
          :componentList="paymentConfig"
          :model="contractData[index]"
          :showbor="false"
          :count="count"
        ></form-info>

        <!-- 合作方式和现金情况 -->

        <form-info
          :componentList="cooperativeConfig"
          :model="contractData[index]"
          :showbor="false"
          :count="count"
        ></form-info>
      </div>
      <div>
        <text-title
          index="02"
          label="项目资金计划"
          data-title="项目资金计划"
          class="modelBox"
        ></text-title>
        <!-- 项目资金计划 -->

        <base-table
          class="main-page-table"
          :columns="planConfig"
          :showPage="false"
          :tableAttrs="{
            data: contractData[index].ddProjectFundingPlanVOs,
            stripe: true
          }"
          ref="tableData"
          :webPage="false"
          :isCaculateHeight="false"
        >
          <template slot="index" slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </base-table>
      </div>

      <div>
        <text-title
          index="03"
          label="评价"
          data-title="评价"
          class="modelBox"
        ></text-title>
        <!-- 评价 -->

        <div
          v-for="(item, indexOn) of contractData[index].ddProjectCommentVOs"
          :key="indexOn"
          style="position: relative"
        >
          <!-- <base-form
            :componentList="ddProjectCommentVOsConfig(indexOn)"
            :formAttrs="{
              model: item,
              labelWidth: '90px',
            }"
            class="formStyle"
            :showBtns="false"
            style="padding-bottom: 0px"
          >
          </base-form> -->
          <form-info
            :componentList="ddProjectCommentVOsConfig(indexOn)"
            :model="item"
            :count="count"
            :showbor="false"
          />
          <div class="textarea">
            <sz-input
              type="textarea"
              placeholder="请输入"
              maxlength="500"
              rows="5"
              v-model="item.comment"
              show-word-limit
              :disabled="true"
              v-if="item.comment"
            ></sz-input>
            <div v-else>--</div>
          </div>
          <enclosure-upload
            :isHiddenBtn="true"
            class="upload"
            :fileInfos="item.finFileInfos"
          />
        </div>
      </div>
    </div>

    <!-- 选择合同信息 -->
    <select-contract
      :show.sync="show"
      @choseContract="choseContract"
      :contractIds="contractIds"
      :gysId="gysId"
    />
    <!-- 楼层 -->
    <scroll-fool :scrollData="scrollData" :scrollBoxId="scrollBoxId" />
  </div>
</template>

<script>
import FormInfo from '@/components/packages/form-info/form-info-new.vue'

// import TitleCenter from '@/pages/business/components/title-center.vue'
import TitleCenter from '@/components/packages/title-center/title-center.vue'
import ScrollFool from '@/components/packages/scroll-fool/scroll-fool.vue'
import EnclosureUpload from '../components/enclosure-upload.vue'
import Storage from '@/utils/storage'
import { contractDdApi } from '@/api/businessApi'
import SelectContract from '../components/select-contract.vue'
import SzInput from '@/components/input/sz-input/sz-input.vue'
import TextTitle from '@/components/packages/text-title/text-title.vue'

import {
  tableConfig,
  formConfig,
  paymentConfig,
  cooperativeConfig,
  planConfig,
  evaluateConfig
} from '../utils/project-contract-info.js'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
export default {
  components: {
    BaseTable,
    TextTitle,
    SzInput,
    SelectContract,
    EnclosureUpload,
    ScrollFool,
    TitleCenter,

    FormInfo
  },
  props: {
    tabsTitles: Array, // 标题错误状态
    showMain: Number, // 当前显示页面
    finContractsData: Array
  },
  data () {
    return {
      scrollBoxId: 'proBox',
      showPage: 1,
      gysId: '',
      contractIds: '',
      show: false,
      count: 0,
      index: 999,
      formData: [],
      scrollData: [
        { label: '基本信息', id: 6 },
        { label: '项目资金计划', id: 7 },
        { label: '评价', id: 8 }
      ]
    }
  },
  // 计算属性 类似于data概念
  computed: {
    contractData: {
      get () {
        return this.finContractsData
      },
      set (val) {}
    },
    api () {
      return contractDdApi
    },
    ddProjectCommentVOsConfig () {
      return function (index) {
        return evaluateConfig(this, index)
      }
    },
    planConfig () {
      return planConfig(this)
    },
    cooperativeConfig () {
      return cooperativeConfig(this)
    },
    paymentConfig () {
      return paymentConfig(this)
    },
    tableConfig () {
      return tableConfig(this)
    },
    formConfig () {
      return formConfig(this)
    }
  },
  // 监控data中的数据变化
  watch: {
    showMain (val) {
      if (val === 1) {
        this.count++
        console.log(this.contractData)
        this.$nextTick(() => {
          this.setMaxHeight(-21)
        })
      }
    },
    index (val) {
      // this.contractData.forEach((item, index) => {
      //   this.$set(this.contractData[index], 'chose', !this.contractData[index].chose)
      //   this.count++
      //   console.log(item, '2')
      // })
    }
  },
  // 方法集合
  methods: {
    // 设置下部分最大高度
    setMaxHeight (num = 0) {
      const orderTableHeight =
        document.getElementById('ordertableList').clientHeight + num
      document.getElementById('proBox').style.maxHeight =
        'calc(100% - ' + orderTableHeight + 'px)'
      console.log(document.getElementById('proBox').style)
    },
    // 保存融资合同及项目信息
    async batchSaveDdFinContracts (data) {
      await this.api
        .batchSaveDdFinContracts({
          businessId: this.$route.query.businessId,
          data: data
        })
        .then(res => {
          if (res.code === '0') {
            return true
          }
        })
    },
    // 保存融资合同及项目信息
    async batchSubmitDdFinContracts (data) {
      await this.api
        .batchSubmitDdFinContracts({
          businessId: this.$route.query.businessId,
          data: data
        })
        .then(res => {
          if (res.code === '0') {
            return true
          }
        })
    },
    // 数据校验
    validate () {
      let pass = { pass: true, index: 0, errMessage: '' }

      try {
        this.contractData.forEach((item, index) => {
          // 基本信息必填校验
          if (
            !item.finContractName ||
            !item.zdMode ||
            !item.paymentType ||
            !item.shareholdingRatio ||
            !item.pledgeValue ||
            !item.relLoanAmount ||
            !item.progressPaymentPercent ||
            !item.settlementPaymentPercent
          ) {
            pass = {
              pass: false,
              index: index,
              errMessage: `请完善${item.contractName}中的基本信息`
            }
            throw Error()
          }
          // 资金计划校验
          // if (item.ddProjectFundingPlanVOs.length === 0) {
          //   pass = {
          //     pass: false,
          //     index: index,
          //     errMessage: `请添加${item.contractName}中的还款计划`
          //   }
          //   throw Error()
          // } else {
          //   item.ddProjectFundingPlanVOs.forEach((elem) => {
          //     if (
          //       !elem.time ||
          //       !elem.buildProcess ||
          //       !elem.expectedDueAmount ||
          //       !elem.expectedInvestAmount ||
          //       !elem.dueInvestType
          //     ) {
          //       pass = {
          //         pass: false,
          //         index: index,
          //         errMessage: `请完善${item.contractName}中的还款计划`
          //       }
          //       throw Error()
          //     }
          //   })
          // }
          // 评价校验
          // if (item.ddProjectCommentVOs.length === 0) {
          //   pass = {
          //     pass: false,
          //     index: index,
          //     errMessage: `请添加${item.contractName}中的评价`
          //   }
          //   throw Error()
          // } else {
          //   item.ddProjectCommentVOs.forEach((elem) => {
          //     if (!elem.evaluatorType || !elem.evaluatorName) {
          //       pass = {
          //         pass: false,
          //         index: index,
          //         errMessage: `请完善${item.contractName}中的评价`
          //       }
          //       throw Error()
          //     }
          //     if (elem.finFileInfos.length === 0) {
          //       pass = {
          //         pass: false,
          //         index: index,
          //         errMessage: `请上传${item.contractName}中的评价附件`
          //       }
          //       throw Error()
          //     }
          //   })
          // }
        })
      } catch (e) {
        // console.log(e)
      }
      console.log(pass.pass)
      if (!pass.pass) {
        if (this.showMain === this.showPage) {
          this.warning(pass.errMessage)
        }
        this.$emit('errMsg', this.showPage, false)
      } else {
        this.$emit('errMsg', this.showPage, true)
      }

      return pass.pass
    },
    // 总体校验
    saveAll (type) {
      console.log('项目合同提交')
      if (type === 'save') {
        this.save()
      } else if (type === 'submit') {
        this.submit()
      }
    },
    // 保存
    save () {
      this.contractData.forEach(item => {
        item.ddContractPaymentInfoDTO = {
          futureProjectPaymentReceivable: item.futureProjectPaymentReceivable,
          futureProjectSettlementReceivable:
            item.futureProjectSettlementReceivable,
          noPayTotalAmount: item.noPayTotalAmount,
          keyId: item.keyId,
          noPayTotalPercent: item.noPayTotalPercent,
          outputTotalAmount: item.outputTotalAmount,
          progressTotalAmount: item.progressTotalAmount,
          retentionTotalAmount: item.retentionTotalAmount,
          settlementTotalAmount: item.settlementTotalAmount
        }
        item.ddFinContractInfoDetailDTO = {
          area: item.area,
          completionPaymentPercent: item.completionPaymentPercent,
          cooperationCashFlow: item.cooperationCashFlow,
          finContractName: item.finContractName,
          paymentType: item.paymentType,
          pledgeRate: item.pledgeRate,
          pledgeValue: item.pledgeValue,
          progressPaymentPercent: item.progressPaymentPercent,
          qualityGuaranteePercent: item.qualityGuaranteePercent,
          relLoanAmount: item.relLoanAmount,
          settlementPaymentPercent: item.settlementPaymentPercent,
          shareholdingRatio: item.shareholdingRatio,
          zdMode: item.zdMode
        }
        item.ddProjectCommentDTOs = item.ddProjectCommentVOs
        item.ddProjectFundingPlanDTOs = item.ddProjectFundingPlanVOs
      })

      this.batchSaveDdFinContracts(this.contractData)
    },
    // 提交
    submit () {
      const pass = this.validate()

      this.contractData.forEach(item => {
        item.ddContractPaymentInfoDTO = {
          futureProjectPaymentReceivable: item.futureProjectPaymentReceivable,
          futureProjectSettlementReceivable:
            item.futureProjectSettlementReceivable,
          noPayTotalAmount: item.noPayTotalAmount,
          keyId: item.keyId,
          noPayTotalPercent: item.noPayTotalPercent,
          outputTotalAmount: item.outputTotalAmount,
          progressTotalAmount: item.progressTotalAmount,
          retentionTotalAmount: item.retentionTotalAmount,
          settlementTotalAmount: item.settlementTotalAmount
        }
        item.ddFinContractInfoDetailDTO = {
          area: item.area,
          completionPaymentPercent: item.completionPaymentPercent,
          cooperationCashFlow: item.cooperationCashFlow,
          finContractName: item.finContractName,
          paymentType: item.paymentType,
          pledgeRate: item.pledgeRate,
          pledgeValue: item.pledgeValue,
          progressPaymentPercent: item.progressPaymentPercent,
          qualityGuaranteePercent: item.qualityGuaranteePercent,
          relLoanAmount: item.relLoanAmount,
          settlementPaymentPercent: item.settlementPaymentPercent,
          shareholdingRatio: item.shareholdingRatio,
          zdMode: item.zdMode
        }
        item.ddProjectCommentDTOs = item.ddProjectCommentVOs
        item.ddProjectFundingPlanDTOs = item.ddProjectFundingPlanVOs
      })
      if (pass) {
        this.batchSubmitDdFinContracts(this.contractData)
      }
    },
    // 修改转让价值
    changePledgeValue (data) {
      if (
        this.contractData[this.index].pledgeValue === 0 ||
        this.contractData[this.index].pledgeValue === '0'
      ) {
        if (this.showMain === this.showPage) {
          this.warning('转让价值不能为0')
        }

        this.$set(this.contractData[this.index], 'pledgeValue', '')
        return
      }
      this.$set(
        this.contractData[this.index],
        'pledgeRate',
        (
          this.contractData[this.index].relLoanAmount /
          this.contractData[this.index].pledgeValue
        ).toFixed(2)
      )
    },
    // 修改融资金额
    changeRelLoanAmount (data) {
      this.$set(
        this.contractData[this.index],
        'pledgeRate',
        (
          this.contractData[this.index].relLoanAmount /
          this.contractData[this.index].pledgeValue
        ).toFixed(2)
      )
    },

    // 新增合同
    showSelect () {
      this.gysId = Storage.getLocal('contractInfo').gysId
      const selectArr = []
      this.contractData.forEach(item => {
        selectArr.push(item.finContractId)
      })
      this.contractIds = selectArr.join(',')
      this.show = true
    },

    // 动态计算付款情况
    getDynamicPaymentInfo () {
      const params = {
        completionPaymentPercent: this.contractData[this.index]
          .completionPaymentPercent
          ? this.contractData[this.index].completionPaymentPercent
          : '0', // 竣工款比例
        finContractAmount: this.contractData[this.index].finContractAmount, // 合同对价
        outputTotalAmount: this.contractData[this.index].outputTotalAmount, // 完成工程产值
        paidAmount: this.contractData[this.index].paidAmount, // 已付金额
        progressPaymentPercent: this.contractData[this.index]
          .progressPaymentPercent, // 进度款比例
        qualityGuaranteePercent: this.contractData[this.index]
          .qualityGuaranteePercent
          ? this.contractData[this.index].qualityGuaranteePercent
          : '0', // 质保金比例
        settlementPaymentPercent: this.contractData[this.index]
          .settlementPaymentPercent // 结算款比例
      }
      let pass = true
      for (const key in params) {
        if (!params[key]) {
          pass = false
        }
      }
      if (!pass) {
        return
      }
      this.api.getDynamicPaymentInfo(params).then(res => {
        this.$set(
          this.contractData[this.index],
          'futureProjectPaymentReceivable',
          res.data.futureProjectPaymentReceivable
        )
        this.$set(
          this.contractData[this.index],
          'futureProjectSettlementReceivable',
          res.data.futureProjectSettlementReceivable
        )
        this.$set(
          this.contractData[this.index],
          'noPayTotalAmount',
          res.data.noPayTotalAmount
        )
        this.$set(
          this.contractData[this.index],
          'noPayTotalPercent',
          res.data.noPayTotalPercent
        )
        this.$set(
          this.contractData[this.index],
          'outputTotalAmount',
          res.data.outputTotalAmount
        )
        this.$set(
          this.contractData[this.index],
          'progressTotalAmount',
          res.data.progressTotalAmount
        )
        this.$set(
          this.contractData[this.index],
          'retentionTotalAmount',
          res.data.retentionTotalAmount
        )
        this.$set(
          this.contractData[this.index],
          'settlementTotalAmount',
          res.data.settlementTotalAmount
        )
      })
    },
    // 批量选择合同
    choseContract (data) {
      data.forEach(item => {
        item.chose = false
        const newItem = JSON.parse(JSON.stringify(item))
        this.contractData.push(newItem)
      })
    },
    // 删除评价
    delddProjectCommentVOs (index) {
      this.contractData[this.index].ddProjectCommentVOs.splice(index, 1)
    },
    // 新增评价
    addddProjectCommentVOs () {
      this.contractData[this.index].ddProjectCommentVOs.push({
        finFileInfos: []
      })
      console.log(this.contractData[this.index].ddProjectCommentVOs)
    },
    // 删除 资金计划
    delPlan (index) {
      this.contractData[this.index].ddProjectFundingPlanVOs.splice(index, 1)
    },
    // 新增项目资金计划
    addPlan () {
      if (
        this.contractData[this.index].ddProjectFundingPlanVOs &&
        this.contractData[this.index].ddProjectFundingPlanVOs.length >= 30
      ) {
        if (this.showMain === this.showPage) {
          this.warning('项目资金计划最多添加30行')
        }
      } else {
        this.contractData[this.index].ddProjectFundingPlanVOs.push({
          add: true
        })
      }
    },
    // 实际合同修改
    finContractNameInput (data) {
      this.$set(this.contractData[this.index], 'finContractName', data)
    },
    // 修改合同选中
    change (index) {
      console.log(index, 'index')
      this.index = index
      this.contractData.forEach((item, indexOn) => {
        if (index === indexOn) {
          this.$set(this.contractData[indexOn], 'chose', true)
        } else {
          this.$set(this.contractData[indexOn], 'chose', false)
        }
      })
      this.$refs.tableData.$forceUpdate()
    },
    // 删除
    del (index) {
      if (this.contractData.length === 1) {
        this.warning('项目合同不能为空')
        return false
      }
      this.contractData.splice(index, 1)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.index = 0
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.upload {
  padding: 0 0 0 10px;
}
.mackProcess {
  padding: 0;
}

.scrollBox {
  max-height: calc(100vh - 377px);
  overflow-y: auto;
}
.delIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #2862e7;
  z-index: 1111;
  cursor: pointer;
  .iconfont {
    font-size: 22px;
  }
}
.textarea {
  padding: 0 18px;
  margin-bottom: 10px;
  /deep/.el-textarea__inner {
    cursor: pointer;
    border: 1px solid #d9e5ee !important;
    &:hover {
      border: 1px solid #2862e7 !important;
    }
  }
}

.addBtn {
  display: flex;
  justify-content: flex-end;
  background: #fff;
  padding-right: 19px;
  padding-top: 10px;
  .addButton {
    background: #4a7cf0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      background-color: #6996ff;
    }
  }
}
</style>
