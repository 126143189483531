// import SupplierSelect from '@/components/packages/supplier-select/supplier-select'
import { getDictLists } from '@/filters/fromDict'
import {
  formatDate
} from '@/utils/auth/common'
// 查询form
export const formConfig = (content) => {
  const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
  return [
    {
      label: '合同名称',
      prop: 'contractName',
      cols: { xs: 24, sm: 12, md: 8, lg: 10, xl: 6 }
    },
    {
      label: '实际合同名称',
      prop: 'finContractName',

      cols
    },
    {
      label: '中登查询',
      prop: 'zdMode',
      option: getDictLists('CHINA_REGISTER_QUERY'),
      selectedField: ['dictId', 'dictName'],

      type: 'BaseSelect',

      cols
    },
    {
      label: '付款方式',
      prop: 'paymentType',
      option: getDictLists('PAYMENT_TYPE'),
      selectedField: ['dictId', 'dictName'],
      type: 'BaseSelect',
      cols
    },
    {
      label: '所属区域',
      prop: 'area',

      cols
    },
    {
      label: '金地控股比例(%)',
      prop: 'shareholdingRatio',

      cols
    },
    {
      label: '转让价值(元)',
      prop: 'pledgeValue',
      type: 'Amount',

      cols
    },
    {
      label: '融资金额(元)',
      prop: 'relLoanAmount',
      type: 'Amount',

      cols
    },
    {
      label: '质押率(%)',
      prop: 'pledgeRate',

      cols
    },
    {
      label: '进度款比例(%)',
      prop: 'progressPaymentPercent',

      cols
    },
    {
      label: '竣工款比例(%)',
      prop: 'completionPaymentPercent',

      cols
    },
    {
      label: '结算款比例(%)',
      prop: 'settlementPaymentPercent',

      cols
    },
    {
      label: '质保金比例(%)',
      prop: 'qualityGuaranteePercent',

      cols
    }
  ]
}
export const cooperativeConfig = (content) => {
  return [
    {
      label: '合作方式和现金情况',
      prop: 'cooperationCashFlow',

      span: 24
    }]
}
// 项目资金计划表格
export const planConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'index'
    },
    {
      label: '日期',
      prop: 'time',
      formatter: row => {
        return formatDate(row.time, 'YY-MM-DD')
      }
    },
    {
      label: '建设进度',
      prop: 'buildProcess',
      align: 'left'
    },
    {
      label: '预计收回款项',
      prop: 'expectedDueAmount',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: row => {
        return (row.expectedDueAmount ? row.expectedDueAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '预计投入款项',
      prop: 'expectedInvestAmount',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: row => {
        return (row.expectedDueAmount ? row.expectedDueAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '收入/投入类型',
      prop: 'dueInvestType',
      align: 'left'
    }

  ]
}
// 付款情况
export const paymentConfig = (content) => {
  const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
  return [
    {
      label: '累计完成工程产值(元)',
      prop: 'outputTotalAmount',
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      type: 'Amount',
      cols
    },
    {
      label: '累计应付金额(元)',
      prop: 'noPayTotalAmount',
      attrs: {
        placeholder: '请输入',
        type: 'Amount',
        disabled: true
      },
      type: 'Amount',
      cols
    },
    {
      label: '应付金额进度比例(%)',
      prop: 'noPayTotalPercent',
      attrs: {
        placeholder: '请输入',
        type: 'Amount',
        disabled: true
      },
      type: 'Amount',
      cols
    },
    {
      label: '当前应收竣备款(元)',
      prop: 'progressTotalAmount',
      attrs: {
        placeholder: '请输入',
        type: 'Amount',
        disabled: true
      },
      type: 'Amount',
      cols
    },
    {
      label: '当前应收结算款(元)',
      prop: 'settlementTotalAmount',
      attrs: {
        placeholder: '请输入',
        type: 'Amount',
        disabled: true
      },
      type: 'Amount',
      cols
    },
    {
      label: '当前应收质保金(元)',
      prop: 'retentionTotalAmount',
      attrs: {
        placeholder: '请输入',
        type: 'Amount',
        disabled: true
      },
      type: 'Amount',
      cols
    },
    {
      label: '合同未来应收工程款(元)',
      prop: 'futureProjectPaymentReceivable',
      attrs: {
        placeholder: '请输入',
        type: 'Amount',
        disabled: true
      },
      type: 'Amount',
      cols
    },
    {
      label: '合同未来应收结算款(元)',
      prop: 'futureProjectSettlementReceivable',
      attrs: {
        placeholder: '请输入',
        type: 'Amount',
        disabled: true
      },
      type: 'Amount',
      cols
    }
  ]
}
// 评价
export const evaluateConfig = (content, index) => {
  const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
  return [
    {
      label: '评价人类型',
      prop: 'evaluatorType',
      option: getDictLists('EVALUATOR_TYPE'),
      selectedField: ['dictId', 'dictName'],
      type: 'BaseSelect',
      cols
    },
    {
      label: '评价人姓名',
      prop: 'evaluatorName',
      attrs: {
        placeholder: '请输入',
        type: 'Amount'
      },
      cols
    }

  ]
}
export const contractConfig = (content) => {
  return [
    {
      label: '合同名称',
      prop: 'contractName',
      attrs: {
        placeholder: '请输入合同名称',
        maxlength: '50'
      },
      rules: [
        { required: false, message: '请输入合同名称', trigger: 'change' }
      ]

    },
    {
      label: '合同编号',
      prop: 'contractCode',
      attrs: {
        placeholder: '请输入合同编号',
        maxlength: '50'
      },
      rules: [
        { required: false, message: '请输入合同编号', trigger: 'change' }
      ]

    }
  ]
}
export const contractTableConfig = (content) => {
  return [
    {
      type: 'selection',
      minWidth: '50px'
    },
    {
      label: '合同名称',
      prop: 'contractName'
    },
    {
      label: '合同编号',
      prop: 'finContractCode'
    },
    {
      label: '实际合同名称',
      prop: 'finContractName',
      minWidth: '100px'
    },
    {
      label: '项目公司名称',
      prop: 'projectDebtor',
      minWidth: '100px'
    },
    {
      label: '合同对价(元)',
      prop: 'finContractAmount',
      align: 'right',
      minWidth: '100px',
      formatter: (row) => {
        return row.finContractAmount
          ? row.finContractAmount.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
          : '0'
      }
    },
    {
      label: '转让价值(元)',
      prop: 'relLoanAmount',
      align: 'right',
      minWidth: '100px',
      formatter: (row) => {
        return row.relLoanAmount
          ? row.relLoanAmount.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
          : '0'
      }
    }
  ]
}
export const tableConfig = (content) => {
  return [
    {
      label: '',
      prop: 'radio',
      width: '30px'
    },
    {
      label: '合同名称',
      prop: 'contractName',
      align: 'left'
    },
    {
      label: '合同编号',
      prop: 'finContractCode',
      'show-overflow-tooltip': true
    },
    {
      label: '实际合同名称',
      prop: 'finContractName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '项目公司名称',
      prop: 'projectDebtor',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '合同对价(元)',
      prop: 'finContractAmount',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: (row) => {
        return row.finContractAmount
          ? row.finContractAmount.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
          : '0'
      }
    },
    {
      label: '转让价值(元)',
      prop: 'pledgeValue',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: (row) => {
        return row.pledgeValue
          ? row.pledgeValue.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
          : '0'
      }
    },
    {
      label: '对应融资额(元)',
      prop: 'relLoanAmount',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: (row) => {
        return row.relLoanAmount
          ? row.relLoanAmount.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
          : '0'
      }
    },
    {
      label: '质押率(%)',
      prop: 'pledgeRate',
      'show-overflow-tooltip': true
    }
  ]
}
